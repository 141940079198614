<template>
  <div
    class="items-center justify-center w-7 flex leading-none flex-none"
    v-if="platformName"
  >
    <div class="relative group">
      <font-awesome-icon
        :icon="platformIcon"
        class="text-gray-400 text-base cursor-help"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlatformIcon',
  props: {
    platformName: {
      type: String,
      default: ''
    }
  },
  computed: {
    platformIcon() {
      const platform = this.platformName.toLowerCase();

      if (
        platform.includes('ios') ||
        platform.includes('iphone') ||
        platform.includes('ipad')
      ) {
        return ['fab', 'apple'];
      } else if (platform.includes('android')) {
        return ['fab', 'android'];
      } else {
        return ['fal', 'laptop'];
      }
    }
  }
};
</script>
